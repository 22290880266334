@import '../../assets/mixins';

.policy-dashboard {
  display: flex;
  flex-grow: 1;
  flex-flow: row nowrap;
  height: 100%;
  width: 100%;
  background: var(--color-page-bg);

  .ui.inverted.dimmer {
    position: fixed;
  }
  .list.ui.horizontal.permit-list-counts {
    min-height: 2rem;
    display: flex;
    .item {
      border-width: 1px;
      display: inline-flex;
      flex-direction: column;
      text-transform: capitalize;
      min-width: 3.15rem;
      &.objects {
        border-color: transparent;
      }
      &.attributes {
        min-width: 3.15rem;
      }
      &:last-child {
        border-right: 0;
      }
      .item-count {
        display: block;
        .font-semiBold(1rem, 1.2em);
        color: var(--color-non-click-text);
      }

      .item-name {
        text-transform: capitalize;
      }
      .item-empty {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
      }
    }
  }

  .alert-count span {
    .font-bold(1rem, 1.43em);
    background: var(--color-severity-critical);
    color: var(--color-white);
    border-radius: 20px;
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ruleset-content {
    width: 100%;
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 1.5rem;
      .search-wrapper {
        width: 17rem;
        margin-bottom: 0;
        .ui.input {
          width: 100%;
          input {
            border-radius: 6px;
          }
        }
      }
    }

    header {
      display: flex;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;

      .btn-create-policy {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }

    .cards-grouped {
      display: flex;
      flex-direction: column;
      margin: 0 1.5rem;

      &:last-child {
        margin-bottom: 1.5rem;
      }

      .type-prefix {
        text-transform: uppercase;
      }
      .cards-list {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-auto-rows: 1fr;
        gap: 1.1rem;
        margin-right: 1rem;

        @media screen and (max-width: 992px) {
          grid-template-columns: 100%;
        }
        @media screen and (min-width: 992px) and (max-width: 1400px) {
          grid-template-columns: 50% 50%;
        }

        @media screen and (min-width: 2100px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
      }
      & + .cards-grouped {
        margin-top: 1.7rem;
      }
    }
  }

  .table-ruleset-policies.print {
    thead tr th {
      overflow: initial !important;
    }
  }

  .policy-types-content {
    width: 100%;
    .content-wrapper {
      overflow: hidden;
      border: none;
      .col-poicy-types {
        border-right: 1px solid var(--color-structured-data-border);
      }
      .search-wrapper {
        flex-grow: 1;
        .ui.input {
          width: 100%;
          input {
            border-radius: 6px;
            height: 2.25rem;
          }
        }
      }
      .button-create-new {
        flex-shrink: 0;
        align-self: center;
      }

      .policy-dashboard-content {
        .ant-tabs-nav-wrap {
          margin-left: 1.5rem;
        }
        .ant-tabs-nav {
          margin-bottom: 0;
        }
      }
    }

    header {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-radius: 8px;
      .scr-title {
        .font-medium(1.15rem, 1.75em);
        color: var(--color-text-primary);
        margin-bottom: 0.43em;
      }
      .scr-description {
        flex-shrink: 0;
        align-self: center;
        color: var(--color-text-secondary);
      }
      .header-action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .policy-types-legend {
      .font-semiBold(1rem, 1.72em);
      color: var(--color-legend-policy-types);
      align-items: center;
      display: flex;
      & > span {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .tab-content {
      height: 100%;
      background-color: var(--color-page-bg);

      .rule-set-table-container {
        border-radius: 4px;
        border: 1px solid var(--lb-color-grey-light-6);
      }
    }
  }

  .nav-tabs.button-style .nav-item:not(.active) {
    background-color: var(--color-white);
  }

  .content-wrapper {
    /* content to extend till the bottom leaving some gap, header and table action blocks have constant height */
    min-height: calc(100vh - var(--navbar-height) - 218px);
    border: none;
    border-radius: 0;
    display: flex;

    .policy-list-table-container {
      border-radius: 4px;
      border: 1px solid var(--lb-color-grey-light-6);
      height: 100%;
      width: 100%;
    }

    .no-data-found {
      // align-self: center;
      height: inherit;
      background-color: var(--color-page-bg);
    }
  }
}

.dashboard.policies,
.dashboard.filters {
  border: 0;
  background-color: transparent;
  padding-top: 1.75rem;
  min-height: unset;
  height: 100%;
  .dashboard-content {
    margin-top: 0;
    padding-top: 0;
  }

  .section-above-header {
    padding-left: 1rem;
  }
}

.ruleset-confirm .actions .ui.button:not(.primary) {
  border-radius: 4px;
  position: relative;
  padding: 0.5em 1.43em;
  font-size: 1rem;
  border: 1px solid;
  background-color: var(--color-bg-btn-secondary);
  border-color: var(--color-btn-secondary-border);
  color: var(--color-text-btn-secondary);
  box-shadow: none;
}

@media screen and (min-width: 992px) {
  .dashboard.policies .dashboard-content .section-content {
    height: 100%;
  }
}
@media print {
  .policy-dashboard {
    background: none !important;
  }
}
