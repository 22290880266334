@import '../../assets/mixins.less';
.list-notifications {
  header {
    background-color: white;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
    border-bottom: 1px solid rgba(0, 21, 41, 0.16);
    margin-bottom: 1.5rem;
    .page-title {
      display: flex;
      align-items: center;
    }
    .page-summary {
      .font-medium(1rem, 1.43em);
      color: var(--color-text-secondary);
    }
  }

  .content-wrapper {
    border: none;

    .notification-table-container {
      border-radius: 4px;
      border: 1px solid var(--lb-color-grey-light-6);
    }

    .no-data-found {
      background-color: var(--color-page-bg);
    }
  }
  .notify-emails {
    display: flex;
    align-items: center;
    label {
      .font-semiBold(0.86rem, 1.34em);
      padding: 2px 4px;
      background: #eff4fa;
      border-radius: 2px;
      margin-left: 0.75rem;
      color: #2f363d;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  .notification-title {
    display: flex;
    align-items: center;
  }
  .notify-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.popup-list-notifications {
  width: 31rem;
  header {
    p {
      .font-medium(0.72rem, 1.79em);
      text-transform: uppercase;
      color: var(--color-river-bed);
    }
    .ui.list {
      .item {
        display: inline-flex;
        cursor: pointer;
        .ui.label {
          .font-normal(0.86rem, 1.34em);
          background: #eff4fa;
          border-radius: 2px;
          color: #586069;
          &.active {
            background: #586069;
            color: #eff4fa;
          }
        }
      }
    }
  }
  .separator {
    border-bottom: 1px solid var(--color-table-row-separator);
  }
  .notifications-summary {
    .no-results {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: calc(45vh - 100px); /* Subtracting 100px for top and bottom padding */
      padding: 50 0;
      text-align: center;
    }
  }
  .ui.list.list-notifications {
    .item {
      min-height: 7rem;
      a {
        display: flex;
        .title {
          .font-medium(1rem, 1.43em);
          color: var(--color-text-primary);
        }
        .date-time {
          .font-medium(0.86rem, 1.7em);
          color: #a5acb8;
          margin-top: 0.72rem;
        }
      }
    }
  }
  footer {
    .legend {
      .font-normal(0.86rem, 1.5em);
      color: var(--color-text-primary);
      text-align: center;
      margin-bottom: 1rem;
    }
    .link-details {
      display: flex;
      justify-content: center;
      a {
        color: var(--color-primary);
        .font-semiBold(1rem, 1.25em);
        text-transform: capitalize;
        display: flex;
        align-items: center;
        .icon {
          font-size: 0.8rem;
          font-weight: bold;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.module-thumbnail {
  background: var(--color-small-background);
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 0 !important;
  }
}
