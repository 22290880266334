@import '../..//assets/mixins';

.page-wrapper {
  display: flex;
  flex-flow: row nowrap;
  min-height: 100%;
  flex-grow: 1;
  height: 100%;
  overflow-y: hidden;

  .column-left {
    // Removed for new sidebar implementation
    // width: var(--sidebar-width);
    border-right: 1px solid var(--color-mystic);
    flex-shrink: 0;
    z-index: 1;
  }

  .column-right {
    display: block;
    flex-flow: column nowrap;
    flex-grow: 1;
    background: var(--color-page-bg);
    height: 100%;
    overflow: auto;
    min-width: 75vw;
  }
}

.page:not(.dashboard, .ui) {
  flex-grow: 1;
  margin: 1.75rem;
  background-color: inherit;
}

.page.dashboard {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--navbar-height));

  .dashboard-content {
    flex-grow: 1;
    z-index: 0;
  }

  &.main {
    .dashboard-content {
      margin: 0 auto;
    }
  }
}

.page.standalone {
  margin: 0;
  .dashboard-children {
    padding: 0;
  }
}

.page {
  background: var(--color-page-bg);
  height: fit-content;
  .section-sidebar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 4.5vh;
  }

  .section-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .section-widgets,
  .section-under-header + .section-content {
    margin-top: 2.3rem;
  }
}
